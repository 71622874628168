import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { getKnegUser } from "../../dataaccess/users";
import { KnegUser } from "../../models/user";

export const UserContext = React.createContext<KnegUser | null>(null);

export const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [activeUser, setActiveUser] = React.useState<KnegUser | null>(null);
  const [hasCheckedUser, setHasCheckedUser] = useState<boolean>(false);
  const auth = getAuth();

  useEffect(() => {
    const authCheck = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const knegUser = await getKnegUser(user.uid);
        if (!knegUser.superuser && knegUser.role !== "manager") {
          signOut(auth);
        }
        setActiveUser(knegUser);
        setHasCheckedUser(true);
      } else {
        setActiveUser(null);
        setHasCheckedUser(true);
      }
    });
    return () => authCheck();
  }, [auth]);

  if (!hasCheckedUser) {
    return (
      <div className="loading-frame">
        <div className="loader"></div>
      </div>
    );
  }

  return <UserContext.Provider value={activeUser}>{children}</UserContext.Provider>;
};
