import React, { useContext, useEffect, useState } from "react";
import { MainNav } from "../components/MainNav";
import {
  CardBody,
  CardFooter,
  MainContent,
  MainContentCard,
  MainContentHeader,
} from "../components/ContentTemplate";
import { UserContext } from "../config/components/UserContext";
import { Customer, CustomerData } from "../models/customer";
import {
  createNewCustomer,
  deleteCustomer,
  getCustomers,
  updateCustomer,
} from "../dataaccess/customers";
import { TextInput } from "../components/forms/TextInput";
import { useModal } from "../hooks/useModal";
import { Modal } from "../components/Modal";
import { DropDownMenu } from "../components/DropDownMenu";
import { useSnackBar } from "../hooks/useSnackBar";
import { SnackBar } from "../components/SnackBar";

export const Customers: React.FunctionComponent = () => {
  const knegUser = useContext(UserContext);
  const tenantId = knegUser?.tenant;
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [customerName, setCustomerName] = useState<string>("");
  const [customerAddress, setCustomerAddress] = useState<string>("");
  const [customerContactPerson, setCustomerContactPerson] = useState<string>("");
  const [customerPhone, setCustomerPhone] = useState<string>("");
  const [customerEmail, setCustomerEmail] = useState<string>("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [modalVisible, openModal, closeModal] = useModal();
  const [isValidated, setIsValidated] = useState<boolean>(true);
  const [SnackBarVisible, openSnackBar, closeSnackBar] = useSnackBar();
  const [snackBarMsg, setSnackBarMsg] = useState<string>("");
  const [snackBarType, setSnackBarType] = useState<string>("");

  const handleCreateNewCustomer = async () => {
    if (knegUser === null) return;
    if (customerName === "") {
      setIsValidated(false);
      return;
    }
    const newCustomer: CustomerData = {
      customername: customerName,
      address: customerAddress,
      contactperson: customerContactPerson,
      phone: customerPhone,
      email: customerEmail,
    };

    try {
      createNewCustomer(newCustomer, knegUser.tenant);
      setSnackBarMsg("Kund tillagd");
      setSnackBarType("success");
    } catch (error) {
      setSnackBarMsg("Det gick inte att skapa en ny kund");
      setSnackBarType("error");
    } finally {
      handleModalClose();
      openSnackBar();
      setCustomers(await getUsers(knegUser.tenant));
      setIsValidated(true);
    }
  };

  const handleUpdateCustomer = async () => {
    if (knegUser === null) return;
    if (selectedCustomerId === "") return;
    if (customerName === "") {
      setIsValidated(false);
      return;
    }
    const customer: CustomerData = {
      customername: customerName,
      address: customerAddress,
      contactperson: customerContactPerson,
      phone: customerPhone,
      email: customerEmail,
    };

    try {
      updateCustomer(customer, knegUser.tenant, selectedCustomerId);
      setSnackBarMsg("Kund uppdaterad");
      setSnackBarType("success");
    } catch (error) {
      setSnackBarMsg("Det gick inte att uppdatera kunden");
      setSnackBarType("error");
    } finally {
      handleModalClose();
      openSnackBar();
      setCustomers(await getUsers(knegUser.tenant));
      setIsValidated(true);
    }
  };

  const handleModalClose = () => {
    setCustomerName("");
    setCustomerAddress("");
    setCustomerContactPerson("");
    setCustomerPhone("");
    setCustomerEmail("");
    setSelectedCustomerId("");
    setIsValidated(true);
    closeModal();
  };

  const removeCustomer = async (customerId: string) => {
    if (knegUser === null) return;
    try {
      deleteCustomer(knegUser.tenant, customerId);
      setSnackBarMsg("Kund borttagen");
      setSnackBarType("info");
    } catch (error) {
      setSnackBarMsg("Det gick inte att ta bort kunden");
      setSnackBarType("error");
    } finally {
      openSnackBar();
      setCustomers(await getUsers(knegUser.tenant));
    }
  };

  const editCustomer = async (customer: Customer) => {
    setIsEditing(true);
    openModal();

    setCustomerName(customer.customername);
    setCustomerAddress(customer.address);
    setCustomerContactPerson(customer.contactperson);
    setCustomerPhone(customer.phone);
    setCustomerEmail(customer.email);
    setSelectedCustomerId(customer.id);
  };

  const addCustomer = () => {
    setIsEditing(false);
    openModal();
  };

  async function getUsers(companyId: string) {
    return await getCustomers(companyId);
  }

  useEffect(() => {
    async function fetchCustomers(companyId: string) {
      setCustomers(await getUsers(companyId));
    }
    if (tenantId !== undefined) fetchCustomers(tenantId);
  }, [tenantId]);

  const content = (
    <React.Fragment>
      <form>
        <TextInput
          name="companyname"
          placeholder="Kundnamn"
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
          required={true}
        />
        {isValidated ? undefined : <p className="error-msg">Kundnamn måste fyllas i</p>}
        <TextInput
          name="address"
          placeholder="Adress"
          value={customerAddress}
          onChange={(e) => setCustomerAddress(e.target.value)}
        />
        <TextInput
          name="contactperson"
          placeholder="Kontaktperson"
          value={customerContactPerson}
          onChange={(e) => setCustomerContactPerson(e.target.value)}
        />
        <TextInput
          name="phone"
          type="tel"
          placeholder="Telefonnummer"
          value={customerPhone}
          onChange={(e) => setCustomerPhone(e.target.value)}
        />
        <TextInput
          name="email"
          type="email"
          placeholder="E-post"
          value={customerEmail}
          onChange={(e) => setCustomerEmail(e.target.value)}
        />
      </form>
    </React.Fragment>
  );
  const footer = (
    <React.Fragment>
      <div className="btn" onClick={handleModalClose}>
        Avbryt
      </div>
      <div
        className="btn primary"
        onClick={isEditing ? handleUpdateCustomer : handleCreateNewCustomer}
      >
        {isEditing ? "Uppdatera" : "Lägg till"}
      </div>
    </React.Fragment>
  );

  return (
    <div className="KnegDashboard Customers">
      <MainNav />
      <MainContent>
        <MainContentHeader>
          <h3>Kunder</h3>
        </MainContentHeader>
        <MainContentCard>
          <CardBody>
            <table className="DefaultTable">
              <thead>
                <tr>
                  <th>Namn</th>
                  <th>Adress</th>
                  <th>Kontaktperson</th>
                  <th>E-post</th>
                  <th>Telefon</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {customers.map((customer) => (
                  <tr key={customer.id}>
                    <td>{customer.customername}</td>
                    <td>{customer.address}</td>
                    <td>{customer.contactperson}</td>
                    <td>{customer.email}</td>
                    <td>{customer.phone}</td>
                    <td className="iconCol">
                      <DropDownMenu>
                        <li onClick={(e: any) => editCustomer(customer)}>
                          <span className="dd-icon edit"></span>Redigera
                        </li>
                        <li onClick={(e: any) => removeCustomer(customer.id)}>
                          <span className="dd-icon delete"></span>
                          Ta bort
                        </li>
                      </DropDownMenu>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardBody>
          <CardFooter>
            <div className="btn primary" onClick={addCustomer}>
              Lägg till kund
            </div>
            <Modal
              visible={modalVisible}
              close={handleModalClose}
              modalHeader={isEditing ? "Redigera kund" : "Lägg till kund"}
              modalContent={content}
              modalFooter={footer}
            />
          </CardFooter>
        </MainContentCard>
      </MainContent>
      <SnackBar
        visible={SnackBarVisible}
        close={closeSnackBar}
        text={snackBarMsg}
        type={snackBarType}
      />
    </div>
  );
};
