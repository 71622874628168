import React, { ChangeEvent } from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MainContent, MainContentCard, MainContentHeader } from "../components/ContentTemplate";
import { MainNav } from "../components/MainNav";
import Cropper from "react-easy-crop";
import { getKnegUser } from "../dataaccess/users";
import { KnegUser } from "../models/user";
import "./UserDetailsPage.scss";
/* import uploadIcon from "../assets/img/export.svg";
import deleteIcon from "../assets/img/delete.svg"; */
import userIcon from "../assets/img/user.svg";
import mailIcon from "../assets/img/mail.svg";
import { useModal } from "../hooks/useModal";
import { Modal } from "../components/Modal";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { firestoreDb, storage } from "../config/firebase";
import getCroppedImg from "../components/cropImage";
import { doc, updateDoc } from "firebase/firestore";
import { roleTextConverter } from "../utils/roleRename";
import { slimDownName } from "../utils/renameForFileName";
import { useSnackBar } from "../hooks/useSnackBar";
import { SnackBar } from "../components/SnackBar";

export const UserDetailpage: React.FunctionComponent = () => {
  const params = useParams();
  const userId = params.userId;
  const [user, setUser] = React.useState<KnegUser | null>(null);
  const [modalVisible, openModal, closeModal] = useModal();
  const [deleteModalVisible, openDeleteModal, closeDeleteModal] = useModal();
  const [imageUrl, setImageUrl] = useState<string>("");
  const [updatedProfileImage, setUpdatedProfileImage] = useState<string>("");
  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [SnackBarVisible, openSnackBar, closeSnackBar] = useSnackBar();
  const [snackBarMsg, setSnackBarMsg] = useState<string>("");
  const [snackBarType, setSnackBarType] = useState<string>("");

  useEffect(() => {
    async function fetchUser() {
      if (userId === undefined) return;
      setUser(await getKnegUser(userId));
    }
    fetchUser();
  }, [userId]);

  const handleSetImage = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setImageUrl(URL.createObjectURL(file));
      openModal();
    }
  };

  const handleCropChange = (crop: React.SetStateAction<{ x: number; y: number }>) => {
    setCrop(crop);
  };

  const handleCropComplete = (_croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleZoomChange = (zoom: React.SetStateAction<number>) => {
    setZoom(zoom);
  };

  const handleDeleteImage = async () => {
    if (user == null) return;
    const deleteThumbRef = ref(storage, `profiles/${user.id}/${slimDownName(user.name)}`);

    deleteObject(deleteThumbRef)
      .then(async () => {
        //TODO success delete
        const userProfileRef = doc(firestoreDb, `users/${user.id}`);
        await updateDoc(userProfileRef, {
          profilePictureUrl: "",
        }).then(async () => {
          setUpdatedProfileImage("");
          setUser(await getKnegUser(user.id));
          closeDeleteModal();
          setSnackBarMsg("Profilbild borttagen");
          setSnackBarType("info");
        });
      })
      .catch((error) => {
        setSnackBarMsg("Det gick inte att ta bort bilden");
        setSnackBarType("error");
      });
    openSnackBar();
  };

  const handleOnCrop = async () => {
    try {
      const { file, url } = await getCroppedImg(imageUrl, croppedAreaPixels);
      uploadProfilePicture(user, file);
      setUpdatedProfileImage(url);
      closeModal();
    } catch (error) {
      setSnackBarMsg("Det gick inte att beskära bilden");
      setSnackBarType("error");
      openModal();
    }
  };

  const uploadProfilePicture = async (user: KnegUser | null, imageFile: File) => {
    if (user == null || imageFile == null) return;
    const profilePictureRef = ref(storage, `profiles/${user.id}/${slimDownName(user.name)}`);
    uploadBytes(profilePictureRef, imageFile)
      .then((result) => {
        getDownloadURL(result.ref).then(async (newImgUrl) => {
          const userProfileRef = doc(firestoreDb, `users/${user.id}`);
          await updateDoc(userProfileRef, {
            profilePictureUrl: newImgUrl,
          }).then(async () => {
            setUser(await getKnegUser(user.id));
          });
          setSnackBarMsg("Ny profilbild uppladdad");
          setSnackBarType("success");
        });
      })
      .catch((error) => {
        console.log("Something went wrong", error);
        setSnackBarMsg("Det gick inte att ladda upp ny profilbild");
        setSnackBarType("error");
      });
    openSnackBar();
  };

  const deleteModal = (
    <React.Fragment>
      <p>Är du säkert på att du vill ta bort din profilbild?</p>
    </React.Fragment>
  );

  const deleteModalFooter = (
    <React.Fragment>
      <div className="btn" onClick={closeDeleteModal}>
        Avbryt
      </div>
      <div className="btn primary" onClick={handleDeleteImage}>
        Ta bort
      </div>
    </React.Fragment>
  );

  const content = (
    <React.Fragment>
      <Cropper
        image={imageUrl}
        zoom={zoom}
        crop={crop}
        aspect={1}
        onCropChange={handleCropChange}
        onCropComplete={handleCropComplete}
        onZoomChange={handleZoomChange}
        cropShape="round"
        showGrid={false}
      />
    </React.Fragment>
  );

  const footer = (
    <React.Fragment>
      Använd scrollhjulet för att zooma in och ut, dra för att flytta bilden.
      <div className="btn" onClick={closeModal}>
        Avbryt
      </div>
      <div className="btn primary" onClick={handleOnCrop}>
        Spara
      </div>
    </React.Fragment>
  );

  return (
    <div className="KnegDashboard UserDetailPage">
      <MainNav />
      <MainContent>
        <MainContentHeader>
          <h3>Användare</h3>
        </MainContentHeader>
        <MainContentCard>
          <MainContent>
            <div className="profile-wrap">
              <div className="profile-thumb">
                <img
                  className="profile-image"
                  alt="profile-img"
                  src={updatedProfileImage ? updatedProfileImage : user?.profilePictureUrl}
                />
                {!user?.profilePictureUrl ? (
                  <label className="upload-btn">
                    <input
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      name="image"
                      onChange={(e) => handleSetImage(e)}
                    />
                  </label>
                ) : (
                  <div className="delete-btn" onClick={openDeleteModal}></div>
                )}
              </div>
              <div className="profile-txt">
                <ul>
                  <li>
                    <h3>{user?.name}</h3>
                  </li>
                  <li>
                    <img alt="user-icon" src={userIcon} />
                    {roleTextConverter(user?.role)}
                    {user?.superuser ? " / Kneg admin" : ""}
                  </li>
                  <li>
                    <img alt="mail-icon" src={mailIcon} />
                    {user?.email}
                  </li>
                </ul>
              </div>
            </div>
          </MainContent>
        </MainContentCard>
      </MainContent>
      <Modal
        visible={deleteModalVisible}
        close={closeDeleteModal}
        modalHeader="Ta bort bild?"
        modalContent={deleteModal}
        modalFooter={deleteModalFooter}
      />
      <Modal
        visible={modalVisible}
        close={closeModal}
        modalHeader="Beskär bild"
        modalContent={content}
        modalFooter={footer}
        fullscreen={true}
      />
      <SnackBar
        visible={SnackBarVisible}
        close={closeSnackBar}
        text={snackBarMsg}
        type={snackBarType}
      />
    </div>
  );
};
