import { Timestamp } from "firebase/firestore";

export interface Assignment {
  id: string;
  address: string;
  contactPerson: string;
  createdAt: Timestamp;
  createdBy: string;
  customer: string;
  email: string;
  finishedBy: string;
  name: string;
  offerCreatedBy: string;
  offerId: string;
  phoneNumber: string;
  startedBy: string;
  status: string;
}

export const statusToReadable = (status: string) => {
  switch (status) {
    case "created":
        return "Skapad";
    case "offerPending":
        return "Offert skapad";
    case "offerAccepted":
        return "Offert godkänd";
    case "started":
        return "Arbete påbörjad";
    case "finished":
        return "Slutförd";
    default:
        return "Okänt";
  }
}
