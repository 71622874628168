import React, { FunctionComponent } from "react";
import ReactDOM from "react-dom";
import "./SnackBar.scss";

export interface SnackBarProps {
  visible: boolean;
  close: () => void;
  text: string;
  type?: string;
  duration?: number;
}

export const SnackBar: FunctionComponent<SnackBarProps> = ({
  visible,
  close,
  text,
  type,
  duration,
}) => {
  const timerDuration = duration || 5;
  const animationDuration = { animationDuration: `${timerDuration}s` } as React.CSSProperties;
  const displayHandler = () => {
    const timer = setTimeout(() => {
      clearTimeout(timer);
      close();
    }, timerDuration * 1000); // close snackbar after timer runs out
  };

  if (visible) {
    displayHandler();
  }
  const snackbar = (
    <React.Fragment>
      <div className="SnackBar" style={animationDuration}>
        <div className={"snackBarIcon " + type}></div>
        <div className="snackBarText">{text}</div>
        <div className="close-snackbar-btn" onClick={close}></div>
        <div className="snackBarDurationProgress">
          <div className={"progress " + type} style={animationDuration}></div>
        </div>
      </div>
    </React.Fragment>
  );

  return visible ? ReactDOM.createPortal(snackbar, document.body) : null;
};
