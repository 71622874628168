import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useForm } from "../hooks/useForm";
import "./Login.scss";
import "../App.scss";
import knegLogo from "../assets/img/kneg-black.svg";
import { getKnegUser } from "../dataaccess/users";

export interface ILoginProps {}

interface FormFields {
  email: string;
  password: string;
}

const Login: React.FunctionComponent<ILoginProps> = (props) => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [authing, setAuthing] = useState(false);
  const [userError, setUserError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [hasAccess, setHasAccess] = useState(true);
  const initialState: FormFields = {
    email: "",
    password: "",
  };
  const [hasCheckedUser, setHasCheckedUser] = useState<boolean>(false);

  useEffect(() => {
    console.log("AUTH CHECKING", authing);
    const authCheck = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const knegUser = await getKnegUser(user.uid);
        if (!knegUser.superuser && knegUser.role !== "manager") {
          setHasAccess(false);
          setAuthing(false);
          signOut(auth);
        } else {
          setHasAccess(true);
          navigate("/users");
        }
        setHasCheckedUser(true);
      } else {
        setHasCheckedUser(true);
      }
    });
    return () => authCheck();
  }, [auth, authing, navigate]);

  const { onChange, onSubmit, values } = useForm<FormFields>(loginUserCallback, initialState);

  function loginUserCallback() {
    setAuthing(true);
    setUserError("");
    setPasswordError("");
    signInWithEmailAndPassword(auth, values.email, values.password)
      .then((userCredential) => {
        // const user = userCredential.user;
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage);
        if (errorCode === "auth/user-not-found") {
          setUserError("Finns ingen användare med angiven e-post");
        }
        if (errorCode === "auth/wrong-password") {
          setPasswordError("Felaktigt lösenord");
        }
        setAuthing(false);
      });
  }

  if (!hasCheckedUser) {
    return (
      <div className="loading-frame">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="Login">
      <div className="login-wrap">
        <img src={knegLogo} alt="Kneg Logo" />
        <form onSubmit={onSubmit}>
          <div>
            <input
              name="email"
              id="email"
              type="email"
              placeholder="Email"
              onChange={onChange}
              required
            />
            <div className="error-msg">{userError}</div>
            <input
              name="password"
              id="password"
              type="password"
              placeholder="Password"
              onChange={onChange}
              required
            />
            <div className="error-msg">{passwordError}</div>
            {!hasAccess ? <div className="error-msg">Användaren har ej behörighet</div> : ""}
            <button className={authing ? "btn loading" : "btn"} type="submit" disabled={authing}>
              {authing ? "Loggar in" : "Logga in"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
