import React, { useContext, useEffect, useState } from "react";
import { MainNav } from "../components/MainNav";
import "./Template.scss";
import {
  CardBody,
  CardFooter,
  MainContent,
  MainContentCard,
  MainContentHeader,
} from "../components/ContentTemplate";
import { Link, useParams } from "react-router-dom";
import { TextInput } from "../components/forms/TextInput";
import { UserContext } from "../config/components/UserContext";
import { InfoField, Step, Task, TaskData } from "../models/template";
import { createNewTask, deleteTask, getTasks, updateTask } from "../dataaccess/templates";
import { DropDownMenu } from "../components/DropDownMenu";
import { Modal } from "../components/Modal";
import { useModal } from "../hooks/useModal";
import { Box, Tooltip, Typography } from "@mui/material";
import { Timestamp } from "firebase/firestore";
import deleteIcon from "../assets/img/delete.svg";
import infoIcon from "../assets/img/info.svg";
import checkIcon from "../assets/img/check-black.svg";
import noteIcon from "../assets/img/notes.svg";
import addIcon from "../assets/img/add-outline.svg";

export const Template: React.FunctionComponent = () => {
  const knegUser = useContext(UserContext);
  const tenantId = knegUser?.tenant;
  const params = useParams();
  const templateId = params.templateId;
  const [isTask, setIsTask] = useState<boolean>(true);
  const [isValidated, setIsValidated] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [message, setMessage] = useState<string>("");
  const [tasks, setTasks] = useState<Task[]>([]);
  const [infos, setInfos] = useState<Task[]>([]);
  const [loading, setLoading] = useState(true);
  const [editingTask, setEditingTask] = useState(false);
  const [modalVisible, openModal, closeModal] = useModal();
  const [title, setTitle] = useState<string>("");
  const [createdAt, setCreatedAt] = useState(Timestamp.now());
  const [steps, setSteps] = useState<Step[]>([]);
  const [infoFields, setInfoFields] = useState<InfoField[]>([]);
  const [selectedId, setSelectedId] = useState<string>("");
  const [taskType, setTaskType] = useState<string>("");
  const [isEditing, setIsEditing] = useState<boolean>(true);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isTask) {
      handleCreateNewTask("tasks");
    } else {
      handleCreateNewTask("info");
    }
  };

  const handleCreateNewTask = async (type: string) => {
    if (knegUser === null) return;
    if (message === "") {
      setIsValidated(false);
      return;
    }
    const newTask: TaskData = {
      title: message,
      steps: [],
      infoFields: [],
      createdAt: Timestamp.now(),
    };

    try {
      if (templateId === undefined) return;
      createNewTask(newTask, knegUser.tenant, templateId, type);
      updateTaskList(knegUser.tenant, templateId, type);
    } catch (error) {
    } finally {
      setIsValidated(true);
    }
    setMessage("");
  };

  const removeTask = async (taskId: string, type: string) => {
    if (knegUser === null) return;
    try {
      if (templateId === undefined) return;
      deleteTask(knegUser.tenant, templateId, taskId, type);
      updateTaskList(knegUser.tenant, templateId, taskType);
    } catch (error) {
    } finally {
      closeModal();
    }
  };

  const editTask = async (task: Task, type: string) => {
    setIsEditing(true);
    setEditingTask(type === "info" ? false : true);
    openModal();

    setTitle(task.title);
    setCreatedAt(task.createdAt);
    setInfoFields(task.infoFields);
    setSteps(task.steps);
    setSelectedId(task.id);
    setTaskType(type);
  };

  const deleteModal = async (task: Task, type: string) => {
    setIsEditing(false);
    openModal();

    setTitle(task.title);
    setCreatedAt(task.createdAt);
    setSteps(task.steps);
    setInfoFields(task.infoFields);
    setSelectedId(task.id);
    setTaskType(type);
  };

  const handleUpdate = async () => {
    if (knegUser === null) return;
    if (selectedId === "") return;
    if (title === "") {
      setIsValidated(false);
      setErrorMessage("Namn måste fyllas i");
      return;
    }
    if (infoFields.find((a) => a.title === "")) {
      setIsValidated(false);
      setErrorMessage("Fyll i titel på alla informationsfält");
      return;
    }
    if (steps.find((s) => s.title === "")) {
      setIsValidated(false);
      setErrorMessage("Fyll i titel på alla steg");
      return;
    }
    const task: TaskData = {
      title: title,
      steps: steps,
      infoFields: infoFields,
      createdAt: createdAt,
    };

    try {
      if (templateId === undefined) return;
      updateTask(task, knegUser.tenant, templateId, selectedId, taskType);
      updateTaskList(knegUser.tenant, templateId, taskType);
    } catch (error) {
      console.log(error);
    } finally {
      closeModal();
      setIsValidated(true);
      setErrorMessage(undefined);
    }
  };

  async function updateTaskList(companyId: string, templateId: string, type: string) {
    if (type === "tasks") {
      setTasks(await getTasks(companyId, templateId, "tasks"));
    }
    if (type === "info") {
      setInfos(await getTasks(companyId, templateId, "info"));
    }
    return;
  }

  useEffect(() => {
    async function fetchTasks(companyId: string) {
      if (templateId == null) {
        setLoading(false);
        return;
      }
      setTasks(await getTasks(companyId, templateId, "tasks"));
      setInfos(await getTasks(companyId, templateId, "info"));
      setLoading(false);
    }
    if (tenantId !== undefined) fetchTasks(tenantId);
  }, [templateId, tenantId]);

  const deleteContent = (
    <React.Fragment>
      <p>Är du säker på att du vill ta bort</p>
      <p>{title}</p>
    </React.Fragment>
  );

  const deleteFooter = (
    <React.Fragment>
      <div className="btn" onClick={closeModal}>
        Avbryt
      </div>
      <div
        className="btn remove"
        onClick={() => {
          removeTask(selectedId, taskType);
        }}
      >
        Ta bort
      </div>
    </React.Fragment>
  );

  const onInfoFieldTitleChange = (title: string, index: number) => {
    setInfoFields(
      infoFields.map((a, i) => {
        if (i === index) {
          return { ...a, title: title };
        } else {
          return a;
        }
      })
    );
  };
  const onStepTitleChange = (title: string, index: number) => {
    setSteps(
      steps.map((s, i) => {
        if (i === index) {
          return { ...s, title: title };
        } else {
          return s;
        }
      })
    );
  };

  const footer = (
    <React.Fragment>
      <div className="btn" onClick={closeModal}>
        Avbryt
      </div>
      <div className="btn primary" onClick={handleUpdate}>
        Spara
      </div>
    </React.Fragment>
  );

  return (
    <div className="KnegDashboard Template">
      <MainNav />
      <MainContent>
        <MainContentHeader>
          <h3>
            <Link to="/templates">Uppdragsmallar</Link> / {params.templateName}
          </h3>
        </MainContentHeader>
        <MainContentCard>
          <CardBody>
            {loading && <h6>Laddar...</h6>}
            {!loading && (
              <>
                <h6>Information</h6>
                {infos.length === 0 && <div>Ingen informaion...</div>}
                {infos.map((info) => (
                  <div className="infoListItem" key={info.id}>
                    <span>{info.title}</span>
                    <DropDownMenu>
                      <li onClick={(e: any) => editTask(info, "info")}>
                        <span className="dd-icon edit"></span>Redigera
                      </li>
                      <li onClick={(e: any) => deleteModal(info, "info")}>
                        <span className="dd-icon delete"></span>
                        Ta bort
                      </li>
                    </DropDownMenu>
                  </div>
                ))}
                <h6>Uppgifter</h6>
                {tasks.length === 0 && <div>Inga uppgifter...</div>}
                {tasks.map((task) => (
                  <div className="taskListItem" key={task.id}>
                    <span onClick={(e: any) => editTask(task, "tasks")}>
                      {task.title}{" "}
                      {task.steps.length > 0 ? (
                        <span className="task-steps-counter">
                          <img src={checkIcon} alt="check-icon" /> {task.steps.length}{" "}
                        </span>
                      ) : (
                        ""
                      )}
                      {task.infoFields.length > 0 ? (
                        <span className="task-steps-counter">
                          <img src={noteIcon} alt="note-icon" /> {task.infoFields.length}{" "}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                    <DropDownMenu>
                      <li onClick={(e: any) => editTask(task, "tasks")}>
                        <span className="dd-icon edit"></span>Redigera
                      </li>
                      <li onClick={(e: any) => deleteModal(task, "tasks")}>
                        <span className="dd-icon delete"></span>
                        Ta bort
                      </li>
                    </DropDownMenu>
                  </div>
                ))}
              </>
            )}
          </CardBody>
          <CardFooter>
            <form onSubmit={onSubmit}>
              <div className="taskTypes">
                <div
                  className={isTask ? "active" : ""}
                  onClick={() => {
                    setIsTask(true);
                  }}
                >
                  Uppgift
                </div>
                <div
                  className={!isTask ? "active" : ""}
                  onClick={() => {
                    setIsTask(false);
                  }}
                >
                  Information
                </div>
              </div>
              <div className="taskTxt">
                <TextInput
                  placeholder="Aa"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <div className="taskSubmit">
                <input type="submit" className="btn primary" value="Lägg till" />
              </div>
            </form>
          </CardFooter>
        </MainContentCard>
      </MainContent>
      <Modal
        visible={modalVisible}
        close={closeModal}
        modalHeader={isEditing ? "Redigera" : "Ta bort"}
        modalContent={
          isEditing ? (
            <DialogContent
              title={title}
              isTask={editingTask}
              isValidated={isValidated}
              errorMessage={errorMessage}
              infoFields={infoFields}
              onTitleChange={setTitle}
              setInfoFields={setInfoFields}
              onInfoFieldTitleChange={onInfoFieldTitleChange}
              setSteps={setSteps}
              onStepTitleChange={onStepTitleChange}
              steps={steps}
            />
          ) : (
            deleteContent
          )
        }
        modalFooter={isEditing ? footer : deleteFooter}
      />
    </div>
  );
};

interface DialogContentProps {
  title: string;
  isTask: boolean;
  infoFields: InfoField[];
  steps: Step[];
  isValidated: boolean;
  errorMessage?: string;
  onTitleChange: (title: string) => void;
  setInfoFields: (infoFields: InfoField[]) => void;
  onInfoFieldTitleChange: (title: string, index: number) => void;
  setSteps: (steps: Step[]) => void;
  onStepTitleChange: (title: string, index: number) => void;
}

const DialogContent: React.FunctionComponent<DialogContentProps> = (props: DialogContentProps) => {
  return (
    <form>
      <Box sx={{ mb: 2 }}>
        <TextInput
          name="title"
          placeholder="Titel"
          value={props.title}
          onChange={(e) => props.onTitleChange(e.target.value)}
          required={true}
        />
      </Box>
      {props.isTask && (
        <>
          <div className="row">
            <Typography sx={{ mb: 0.5, fontWeight: "500", fontFamily: "Poppins" }} variant="h6">
              Informationsfält
            </Typography>
            <Tooltip
              className="tooltip"
              style={{ fontFamily: "Poppins" }}
              title="Med informationsfält kan serviceteknikern enkelt fylla i viktig information om den här uppgiften, till exempel mått och dimensioner."
              placement="top"
            >
              <img style={{ width: 32, height: 35, paddingBottom: 5 }} src={infoIcon} alt="info" />
            </Tooltip>
          </div>
          {props.infoFields.map((infoField, index) => (
            <div key={index} className="small-input row">
              <div className="textfield">
                <TextInput
                  name="infoField-title"
                  placeholder="Informationsfält"
                  value={infoField.title}
                  onChange={(e) => {
                    props.onInfoFieldTitleChange(e.target.value, index);
                  }}
                  required={true}
                />
              </div>
              <div
                onClick={() => props.setInfoFields(props.infoFields.filter((_, i) => i !== index))}
                className="remove-btn"
              >
                <img src={deleteIcon} alt="Ta bort" />
              </div>
            </div>
          ))}
          <div
            className="addSubBtn"
            onClick={() =>
              props.setInfoFields([...props.infoFields, { title: "", createdAt: Timestamp.now() }])
            }
          >
            <img src={addIcon} alt="add-icon" /> Lägg till informationsfält
          </div>
          <Typography
            sx={{ mt: 2, mb: 0.5, fontWeight: "500", fontFamily: "Poppins" }}
            variant="h6"
          >
            Steg
          </Typography>
          {props.steps.map((s, index) => (
            <div key={index} className="small-input row">
              <div className="textfield">
                <TextInput
                  name="step-title"
                  placeholder="Steg"
                  value={s.title}
                  onChange={(e) => {
                    props.onStepTitleChange(e.target.value, index);
                  }}
                  required={true}
                />
              </div>
              <div
                onClick={() => props.setSteps(props.steps.filter((_, i) => i !== index))}
                className="remove-btn"
              >
                <img src={deleteIcon} alt="Ta bort" />
              </div>
            </div>
          ))}
          <div
            className="addSubBtn"
            onClick={() =>
              props.setSteps([...props.steps, { title: "", createdAt: Timestamp.now() }])
            }
          >
            <img src={addIcon} alt="add-icon" /> Lägg till steg
          </div>
        </>
      )}
      <Box>
        {props.isValidated && props.errorMessage ? undefined : (
          <p className="error-msg">{props.errorMessage}</p>
        )}
      </Box>
    </form>
  );
};
