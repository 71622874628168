import React from "react";
import {
  CardBody,
  MainContent,
  MainContentCard,
  MainContentHeader,
} from "../components/ContentTemplate";
import { MainNav } from "../components/MainNav";

export const AppSettings: React.FunctionComponent = () => {
  return (
    <div className="KnegDashboard">
      <MainNav />
      <MainContent>
        <MainContentHeader>
          <h3>Inställningar</h3>
        </MainContentHeader>
        <MainContentCard>
          <CardBody></CardBody>
        </MainContentCard>
      </MainContent>
    </div>
  );
};
