import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "./UserContext";

export interface IAuthRouteProps {}

export const AuthRouteInner: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;
  const navigate = useNavigate();
  const knegUser = React.useContext(UserContext);

  useEffect(() => {
    if (knegUser === null) {
      navigate("/login");
    }
  }, [knegUser, navigate]);

  if (knegUser === null) {
    return null;
  }

  return <>{children}</>;
};

export const AuthRoute: React.FC<React.PropsWithChildren> = (props) => {
  return (
      <AuthRouteInner>{props.children}</AuthRouteInner>
  );
};
