import axios from "axios";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { auth, functions } from "../../config/firebase";
import "./RedeemInvitationPage.scss";
import mailIcon from "../../assets/img/mail.svg";
import userIcon from "../../assets/img/user.svg";
import companyIcon from "../../assets/img/company.svg";
import knegLogo from "../../assets/img/kneg-black.svg";
import checkIcon from "../../assets/img/check-black.svg";
import lostInvitation from "../../assets/img/flies-away.png";
import successInvitation from "../../assets/img/success-ass.png";
import { TextInput } from "../../components/forms/TextInput";

// const getBaseUrl = () => "http://localhost:5000/kaisa-tool/us-central1";
const getBaseUrl = () => "https://us-central1-kaisa-tool.cloudfunctions.net";

interface InvitationData {
  consumed: false;
  email: string;
  role: "employee" | "manager";
  tenantId: string;
  tenantName: string;
}
interface InvitationPayloadUnavail {
  consumed: true;
}
type InvitationPayload = InvitationData | InvitationPayloadUnavail;

export const RedeemInvitationPage: React.FunctionComponent = () => {
  const { tenantId, invitationId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isConsumed, setIsConsumed] = useState<boolean | null>(null);
  const [invitationData, setInvitationData] = useState<InvitationData | null>(null);
  const [name, setName] = useState<string>("");
  const [password1, setPassword1] = useState<string>("");
  const [password2, setPassword2] = useState<string>("");

  useEffect(() => {
    async function fetchInvitationData() {
      setIsLoading(true);
      const url = `${getBaseUrl()}/invitations/${tenantId}/${invitationId}`;
      try {
        const response = await axios.get<InvitationPayload>(url);
        console.log("got response", response);
        if (response.data.consumed === false) {
          setInvitationData(response.data);
          setIsConsumed(false);
          console.log("setting consumed false");
        } else {
          setIsConsumed(true);
          console.log("setting consumed true");
        }
      } catch (e) {
        console.log("It all went wrong.");
      }
      setIsLoading(false);
    }
    fetchInvitationData();
  }, [invitationId, tenantId]);

  if (
    tenantId === undefined ||
    invitationId === undefined ||
    tenantId.length === 0 ||
    invitationId.length === 0
  ) {
    return (
      <div className="RedeemInvitationPage">
        <div className="inviteFormContainer">
          <div className="inviteFormBlock">
            <img src={lostInvitation} alt="Förbrukad inbjudan" />
            <h4>Not found</h4>
          </div>
        </div>
        <div className="inviteBackdrop"></div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="RedeemInvitationPage">
        <div className="inviteFormContainer">
          <div>
            <div className="loading-frame">
              <div className="loader"></div>
            </div>
          </div>
        </div>
        <div className="inviteBackdrop"></div>
      </div>
    );
  }

  if (isConsumed === true) {
    return (
      <div className="RedeemInvitationPage">
        <div className="inviteFormContainer">
          <div className="inviteFormBlock">
            <img src={lostInvitation} alt="Förbrukad inbjudan" />
            <h4>Inbjudan förbrukad.</h4>
            <p>Någon har redan accepterat denna inbjudan</p>
          </div>
        </div>
        <div className="inviteBackdrop"></div>
      </div>
    );
  }

  if (invitationData === null) {
    // This shouldn't happen, if we end up here it's a bug.
    return (
      <div className="RedeemInvitationPage">
        <div className="inviteFormContainer">
          <div className="inviteFormBlock">
            <h4>Ett fel uppstod.</h4>
          </div>
        </div>
        <div className="inviteBackdrop"></div>
      </div>
    );
  }

  if (isSuccess === true) {
    return (
      <div className="RedeemInvitationPage">
        <div className="inviteFormContainer">
          <div className="inviteFormBlock">
            <img src={knegLogo} alt="Kneg" className="inviteLogo" />
            <br />
            <img src={successInvitation} alt="Lyckad registrering" />
            <p className="inviteTagline">Registrering lyckad</p>
            <h1>Dags att börja knega!</h1>
            <p>
              Du kommer att få instruktioner om hur du kan ladda ner appen. Därefter är det bara att
              logga in med dina uppgifter.
            </p>
          </div>
        </div>
        <div className="inviteBackdrop"></div>
      </div>
    );
  }

  const roleToReadable = (role: string) => {
    switch (role) {
      case "manager":
        return "Arbetsledare";
      case "employee":
        return "Servicetekniker";
      default:
        return role;
    }
  };

  const handleSubmit = async () => {
    console.log("on submit");
    setIsSubmitting(true);
    if (password1.length !== 0 && password1 === password2) {
      const credentials = await createUserWithEmailAndPassword(
        auth,
        invitationData.email,
        password1
      );
      console.log("Registered with credentials...", credentials);

      const joinTenant = httpsCallable(functions, "joinTenant");
      console.log("About to try to join tenant");

      try {
        await joinTenant({
          tenantId: tenantId,
          invitationId: invitationId,
          name: name,
        });
        console.log("OK, I guess");
        setIsSuccess(true);
        setIsSubmitting(false);
      } catch (e) {
        console.log("Something went wrong.");
        // TODO: Handle error well in UI.
        setIsSubmitting(false);
      }

      // TODO: Consume invitation to join tenant
    }
  };

  return (
    <div className="RedeemInvitationPage">
      <div className="inviteFormContainer">
        <div className="inviteFormBlock">
          <img src={knegLogo} alt="Kneg" className="inviteLogo" />
          <p className="inviteTagline">Välkommen</p>
          <h1>Registrera användare</h1>
          <ul className="inviteInfoList">
            <li>
              <img src={mailIcon} alt="E-post" />
              {invitationData.email}
            </li>
            <li>
              <img src={userIcon} alt="Roll" />
              {roleToReadable(invitationData.role)}
            </li>
            <li>
              <img src={companyIcon} alt="Företag" />
              {invitationData.tenantName}
            </li>
          </ul>
          <div className="inviteForm">
            <TextInput
              type="text"
              placeholder="Fullständigt namn"
              value={name}
              required={true}
              onChange={(e) => setName(e.target.value)}
            />
            <TextInput
              type="password"
              placeholder="Lösenord"
              value={password1}
              required={true}
              onChange={(e) => setPassword1(e.target.value)}
            />
            <TextInput
              type="password"
              placeholder="Repetera lösenord"
              value={password2}
              required={true}
              onChange={(e) => setPassword2(e.target.value)}
            />
            {isSubmitting ? (
              <div className="btn big icon-btn disabled loading">
                <div className="btn-loading-icon"></div>
                Laddar
              </div>
            ) : (
              <div className="btn big primary primary-glow icon-btn" onClick={handleSubmit}>
                <img src={checkIcon} alt="Registrera" />
                Registrera
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="inviteBackdrop"></div>
    </div>
  );
};
