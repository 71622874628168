import {
  addDoc,
  collection,
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
  getDocs,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { TenantData, Tenant } from "../models/tenant";
import { firestoreDb } from "../config/firebase";

const docToTenant = (doc: QueryDocumentSnapshot<DocumentData>): Tenant => ({
  id: doc.id,
  name: doc.get("name"),
});

const docToSingleTenant = (doc: DocumentSnapshot<DocumentData>): Tenant => ({
  id: doc.id,
  name: doc.get("name"),
});

export const getAllTenants = async (): Promise<Tenant[]> => {
  const tenantsCollection = collection(firestoreDb, "tenants");
  const data = await getDocs(tenantsCollection);
  const tenantList = data.docs.map((doc) => docToTenant(doc));
  return tenantList;
};

export const getTenant = async (tenantId: string): Promise<Tenant> => {
  const tenantCol = doc(firestoreDb, "tenants", tenantId);
  const data = await getDoc(tenantCol);
  return docToSingleTenant(data);
};

export const createNewTenant = async (tenant: TenantData): Promise<Tenant> => {
  const newDoc = await addDoc(collection(firestoreDb, "tenants"), tenant);
  const createdTenant: Tenant = {
    ...tenant,
    id: newDoc.id,
  };
  return createdTenant;
};

export const deleteTenantAndUsers = async (tenantId: string) => {
  //TODO Delete tenant, and loop over all users and remove them.
  return;
};
