import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  getDocs,
  query,
  QueryDocumentSnapshot,
  updateDoc,
} from "firebase/firestore";
import { Customer, CustomerData } from "../models/customer";
import { firestoreDb } from "../config/firebase";

const docsToCustomers = (doc: QueryDocumentSnapshot<DocumentData>): Customer => ({
  id: doc.id,
  address: doc.get("address"),
  contactperson: doc.get("contactperson"),
  customername: doc.get("customername"),
  email: doc.get("email"),
  phone: doc.get("phone"),
});

export const getCustomers = async (tenantId: string): Promise<Customer[]> => {
  const clientsFromTenantRef = query(collection(firestoreDb, "tenants/" + tenantId + "/customers"));
  const data = await getDocs(clientsFromTenantRef);
  const projectList = data.docs.map((doc) => docsToCustomers(doc));
  return projectList;
};

export const createNewCustomer = async (
  customer: CustomerData,
  tenantId: string
): Promise<CustomerData> => {
  const newDoc = await addDoc(
    collection(firestoreDb, "tenants/" + tenantId + "/customers"),
    customer
  );
  const createdCustomer: Customer = {
    ...customer,
    id: newDoc.id,
  };
  return createdCustomer;
};

export const updateCustomer = async (
  customer: CustomerData,
  tenantId: string,
  customerId: string
) => {
  const customerRef = doc(firestoreDb, "tenants/" + tenantId + "/customers", customerId);
  await updateDoc(customerRef, {
    ...customer,
  });
};

export const deleteCustomer = async (tenantId: string, customerId: string) => {
  await deleteDoc(doc(firestoreDb, "tenants/" + tenantId + "/customers", customerId))
    .then(() => {
      console.log("Doc deleted");
    })
    .catch((error) => {
      console.log("Something went wrong on delete ", error);
    });
};
