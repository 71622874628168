import { useContext } from "react";
import "./MainNav.scss";
import { getAuth, signOut } from "firebase/auth";
import knegLogo from "../assets/img/kneg-black.svg";
import knegIcon from "../assets/img/kneg-icon.svg";
import logoutIcon from "../assets/img/logout.svg";
import { Link, NavLink } from "react-router-dom";
import { UserContext } from "../config/components/UserContext";
import { KnegUser } from "../models/user";
import { setProfilePicture } from "./ProfileThumb";

function setUserName(user: KnegUser | null) {
  if (user) {
    return <b>{user.name}</b>;
  }
}

function setUserAvatar(user: KnegUser | null) {
  if (user) {
    return (
      <Link to={`/user/${user.id}`}>
        {setProfilePicture(user)}
        {setUserName(user)}
      </Link>
    );
  }
  return <b>"Inloggad"</b>;
}

export function MainNav() {
  const auth = getAuth();
  const user = useContext(UserContext);

  return (
    <div className="MainNav">
      <img src={knegLogo} className="nav-logo" alt="Kneg Logo" />
      <img src={knegIcon} className="nav-logo-mobile" alt="Kneg Logo" />
      <ul className="navlist">
        {user?.superuser && <li>
          <NavLink to="/companies" className={({ isActive }) => (isActive ? "active" : "")}>
            <div className="menu-icon companies"></div>
            <span className="menu-txt">Företag</span>
          </NavLink>
        </li>}
        <li>
          <NavLink to="/users" className={({ isActive }) => (isActive ? "active" : "")}>
            <div className="menu-icon users"></div>
            <span className="menu-txt">Knegare</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/assignments" className={({ isActive }) => (isActive ? "active" : "")}>
            <div className="menu-icon tasks"></div>
            <span className="menu-txt">Uppdrag</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/customers" className={({ isActive }) => (isActive ? "active" : "")}>
            <div className="menu-icon clients"></div>
            <span className="menu-txt">Kunder</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/templates" className={({ isActive }) => (isActive ? "active" : "")}>
            <div className="menu-icon templates"></div>
            <span className="menu-txt">Uppdragsmallar</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/settings" className={({ isActive }) => (isActive ? "active" : "")}>
            <div className="menu-icon settings"></div>
            <span className="menu-txt">Appiställningar</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/payments" className={({ isActive }) => (isActive ? "active" : "")}>
            <div className="menu-icon payment"></div>
            <span className="menu-txt">Prenumeration</span>
          </NavLink>
        </li>
      </ul>
      <div className="signed-user">
        {setUserAvatar(user)}
        <div onClick={() => signOut(auth)} className="btn icon-btn primary">
          <img alt="logout-icon" src={logoutIcon} />
          <span className="btn-txt">Logga ut</span>
        </div>
      </div>
    </div>
  );
}
