import { FunctionComponent } from "react";
import "../App.scss";
import "./NotFound.scss";
import { Link } from "react-router-dom";

export const NotFound: FunctionComponent = () => {
  return (
    <div className="NotFound">
      <div>
        <h3>Sidan kunde inte hittas</h3>
        <Link to="/" className="btn black">
          Ta mig tillbaka
        </Link>
      </div>
    </div>
  );
};
