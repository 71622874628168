import React, { FunctionComponent } from "react";
import ReactDOM from "react-dom";
import "./Modal.scss";

export interface ModalProps {
  visible: boolean;
  close: () => void;
  modalHeader: string;
  modalContent: JSX.Element;
  modalFooter: JSX.Element;
  fullscreen?: boolean;
}

export const Modal: FunctionComponent<ModalProps> = ({
  visible,
  close,
  modalContent,
  modalHeader,
  modalFooter,
  fullscreen,
}) => {
  const modal = (
    <React.Fragment>
      <div className="modal-backdrop" />
      <div className={fullscreen ? "modal-wrapper fullscreen" : "modal-wrapper"}>
        <div className="modal">
          <div className="modal-header">
            <h5>{modalHeader}</h5>
            <div className="close-modal-btn" onClick={close}></div>
          </div>
          <div className="modal-body">{modalContent}</div>
          <div className="modal-footer">{modalFooter}</div>
        </div>
      </div>
    </React.Fragment>
  );

  return visible ? ReactDOM.createPortal(modal, document.body) : null;
};
