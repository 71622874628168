import React, { useContext, useEffect, useState } from "react";
import { MainNav } from "../components/MainNav";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import {
  CardBody,
  MainContent,
  MainContentCard,
  MainContentHeader,
} from "../components/ContentTemplate";
import { UserContext } from "../config/components/UserContext";
import { Assignment, statusToReadable } from "../models/assignment";
import { getProjects } from "../dataaccess/assignments";
import "../styles/mui-overrides.scss";
import { DateTime as LuxonDate} from "luxon";

export const Assignments: React.FunctionComponent = () => {
  const knegUser = useContext(UserContext);
  const tenantId = knegUser?.tenant;
  const [assignments, setAssignments] = useState<Assignment[]>([]);

  useEffect(() => {
    async function fetchProjects(companyId: string) {
      setAssignments(await getProjects(companyId));
    }
    if (tenantId !== undefined) fetchProjects(tenantId);
  }, [tenantId]);

  const columns: GridColDef[] = [
    { field: "col1", headerName: "Uppdrag", flex: 1, editable: false },
    { field: "col2", headerName: "Kund", flex: 1 },
    { field: "col3", headerName: "Datum skapad", flex: 1 },
    { field: "col4", headerName: "Status", flex: 1 },
  ];

  const rows: GridRowsProp = assignments.map((assignment) => ({
    id: assignment.id,
    col1: assignment.name,
    col2: assignment.customer,
    col3: LuxonDate.fromJSDate(assignment.createdAt.toDate()).toISODate(),
    col4: statusToReadable(assignment.status),
  }));

  return (
    <div className="KnegDashboard">
      <MainNav />
      <MainContent>
        <MainContentHeader>
          <h3>Uppdrag</h3>
        </MainContentHeader>
        <MainContentCard>
          <CardBody>
            <DataGrid
              checkboxSelection
              disableSelectionOnClick
              disableColumnMenu
              rows={rows}
              columns={columns}
            />
          </CardBody>
        </MainContentCard>
      </MainContent>
    </div>
  );
};
