import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { MainContent, MainContentCard, MainContentHeader } from "../components/ContentTemplate";
import { MainNav } from "../components/MainNav";
import "./CompanyDetailPage.scss";
import { UserManagementList } from "../components/UserManagementList";
import { useSnackBar } from "../hooks/useSnackBar";
import { SnackBar } from "../components/SnackBar";

export const CompanyDetailPage: React.FunctionComponent = () => {
  const params = useParams();
  const tenantId = params.companyId;

  const [SnackBarVisible, openSnackBar, closeSnackBar] = useSnackBar();
  const [snackBarMsg, setSnackBarMsg] = useState<string>("");
  const [snackBarType, setSnackBarType] = useState<string>("");

  return (
    <div className="KnegDashboard CompanyDetailPage">
      <MainNav />
      <MainContent>
        <MainContentHeader>
          <h3>
            <Link to="/companies">Företag</Link> / {params.companyName}
          </h3>
        </MainContentHeader>
        <MainContentCard>
          {tenantId !== undefined && (
            <UserManagementList
              tenantId={tenantId}
              setSnackBarMessage={setSnackBarMsg}
              openSnackBar={openSnackBar}
              setSnackBarType={setSnackBarType}
            />
          )}
        </MainContentCard>
      </MainContent>
      <SnackBar
        visible={SnackBarVisible}
        close={closeSnackBar}
        text={snackBarMsg}
        type={snackBarType}
      />
    </div>
  );
};
