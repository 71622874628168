import React, { useContext, useEffect, useState } from "react";
import { MainNav } from "../components/MainNav";
import "./TemplateListPage.scss";
import {
  CardBody,
  CardFooter,
  MainContent,
  MainContentCard,
  MainContentHeader,
} from "../components/ContentTemplate";
import { TextInput } from "../components/forms/TextInput";
import { UserContext } from "../config/components/UserContext";
import { useModal } from "../hooks/useModal";
import { Modal } from "../components/Modal";
import { DropDownMenu } from "../components/DropDownMenu";
import {
  createNewTemplate,
  deleteTemplate,
  getTemplates,
  updateTemplate,
} from "../dataaccess/templates";
import { Template, TemplateData } from "../models/template";
import { useSnackBar } from "../hooks/useSnackBar";
import { SnackBar } from "../components/SnackBar";
import { Link } from "react-router-dom";

export const TemplateList: React.FunctionComponent = () => {
  const knegUser = useContext(UserContext);
  const tenantId = knegUser?.tenant;
  const [templateName, setTemplateName] = useState<string>("");
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>("");
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isValidated, setIsValidated] = useState<boolean>(true);
  const [modalVisible, openModal, closeModal] = useModal();
  const [deleteModalVisible, openDeleteModal, closeDeleteModal] = useModal();
  const [templates, setTemplates] = useState<Template[]>([]);
  const [SnackBarVisible, openSnackBar, closeSnackBar] = useSnackBar();
  const [snackBarMsg, setSnackBarMsg] = useState<string>("");
  const [snackBarType, setSnackBarType] = useState<string>("");

  const addCustomer = () => {
    setIsEditing(false);
    openModal();
  };

  const handleModalClose = () => {
    setTemplateName("");
    setIsValidated(true);
    closeModal();
  };

  async function getUsers(companyId: string) {
    return await getTemplates(companyId);
  }

  const handleCreateNewTemplate = async () => {
    if (knegUser === null) return;
    if (templateName === "") {
      setIsValidated(false);
      return;
    }
    const newTemplate: TemplateData = {
      templatename: templateName,
    };

    try {
      createNewTemplate(newTemplate, knegUser.tenant);
      setSnackBarMsg(`Mallen ${templateName} tillagd`);
      setSnackBarType("success");
    } catch (error) {
      setSnackBarMsg("Det gick inte att skapa en ny mall");
      setSnackBarType("error");
    } finally {
      handleModalClose();
      openSnackBar();
      setTemplates(await getUsers(knegUser.tenant));
      setIsValidated(true);
      setTemplateName("");
    }
  };

  const handleUpdateTemplate = async () => {
    if (knegUser === null) return;
    if (selectedTemplateId === "") return;
    if (templateName === "") {
      setIsValidated(false);
      return;
    }
    const template: TemplateData = {
      templatename: templateName,
    };

    try {
      updateTemplate(template, knegUser.tenant, selectedTemplateId);
      setSnackBarMsg("Mall uppdaterad");
      setSnackBarType("success");
    } catch (error) {
      setSnackBarMsg("Det gick inte att uppdatera mallen");
      setSnackBarType("error");
    } finally {
      handleModalClose();
      openSnackBar();
      setTemplates(await getUsers(knegUser.tenant));
      setIsValidated(true);
      setTemplateName("");
    }
  };

  const removeTemplate = async (templateId: string) => {
    if (knegUser === null) return;
    try {
      deleteTemplate(knegUser.tenant, templateId);
      setSnackBarMsg(`Mallen ${templateName} borttagen`);
      setSnackBarType("info");
      setTemplates(await getUsers(knegUser.tenant));
    } catch (error) {
      setSnackBarMsg("Det gick inte att ta bort mallen");
      setSnackBarType("error");
    } finally {
      openSnackBar();
      closeDeleteModal();
    }
  };

  const editTemplate = async (template: Template) => {
    setIsEditing(true);
    openModal();

    setTemplateName(template.templatename);
    setSelectedTemplateId(template.id);
  };

  const deleteModal = async (template: Template) => {
    setTemplateName(template.templatename);
    setSelectedTemplateId(template.id);

    openDeleteModal();
  };

  useEffect(() => {
    async function fetchTemplates(companyId: string) {
      setTemplates(await getTemplates(companyId));
    }
    if (tenantId !== undefined) fetchTemplates(tenantId);
  }, [tenantId]);

  const content = (
    <React.Fragment>
      <form>
        <TextInput
          name="templatename"
          placeholder="Mallnamn"
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
          required={true}
        />
        {isValidated ? undefined : <p className="error-msg">Namn måste fyllas i</p>}
      </form>
    </React.Fragment>
  );
  const footer = (
    <React.Fragment>
      <div className="btn" onClick={handleModalClose}>
        Avbryt
      </div>
      <div
        className="btn primary"
        onClick={isEditing ? handleUpdateTemplate : handleCreateNewTemplate}
      >
        {isEditing ? "Uppdatera" : "Lägg till"}
      </div>
    </React.Fragment>
  );

  const deleteContent = (
    <React.Fragment>
      <p>
        Är du säkert på att du vill ta bort mallen <b>{templateName}</b> ?
      </p>
      <p>All tillhörande information och uppgifter kommer också att tas bort.</p>
    </React.Fragment>
  );
  const deleteFooter = (
    <React.Fragment>
      <div className="btn" onClick={closeDeleteModal}>
        Avbryt
      </div>
      <div
        className="btn remove"
        onClick={() => {
          removeTemplate(selectedTemplateId);
        }}
      >
        Ta bort
      </div>
    </React.Fragment>
  );

  return (
    <div className="KnegDashboard TemplateListPage">
      <MainNav />
      <MainContent>
        <MainContentHeader>
          <h3>Uppdragsmallar</h3>
        </MainContentHeader>
        <MainContentCard>
          <CardBody>
            {templates.length === 0 && "Finns inga mallar ännu! Prova skapa ett med knappen nedan"}
            {templates.map((template) => (
              <div className="templateListItem" key={template.id}>
                <Link key={template.id} to={`/templates/${template.templatename}/${template.id}`}>
                  {template.templatename}
                </Link>

                <DropDownMenu>
                  <li onClick={(e: any) => editTemplate(template)}>
                    <span className="dd-icon edit"></span>Redigera
                  </li>
                  <li onClick={(e: any) => deleteModal(template)}>
                    <span className="dd-icon delete"></span>
                    Ta bort
                  </li>
                </DropDownMenu>
              </div>
            ))}
          </CardBody>
          <CardFooter>
            <div className="btn primary" onClick={addCustomer}>
              Lägg till mall
            </div>
            <Modal
              visible={modalVisible}
              close={handleModalClose}
              modalHeader={isEditing ? "Redigera mall" : "Lägg till uppdragsmall"}
              modalContent={content}
              modalFooter={footer}
            />
            <Modal
              visible={deleteModalVisible}
              close={closeDeleteModal}
              modalHeader="Ta bort mall?"
              modalContent={deleteContent}
              modalFooter={deleteFooter}
            />
          </CardFooter>
        </MainContentCard>
      </MainContent>
      <SnackBar
        visible={SnackBarVisible}
        close={closeSnackBar}
        text={snackBarMsg}
        type={snackBarType}
      />
    </div>
  );
};
