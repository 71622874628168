import "./TextInput.scss";

export interface TextInputProps {
  placeholder?: string;
  label?: string;
  name?: string;
  type?: string;
  required?: boolean;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}
export const TextInput: React.FC<TextInputProps> = (props) => {
  const required = props.required || false;
  const type = props.type || "text";
  return (
    <label className="TextInputLabel">
      {props.label}
      {required && props.label && "*"}
      <input
        className="TextInput"
        name={props.name}
        type={type}
        placeholder={(required && props.placeholder + "*") || props.placeholder}
        value={props.value}
        onChange={props.onChange}
      />
    </label>
  );
};
