import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CardFooter } from "../components/ContentTemplate";
import { MainNav } from "../components/MainNav";
import { createNewTenant, getAllTenants } from "../dataaccess/tenants";
import { Tenant, TenantData } from "../models/tenant";
import {
  CardBody,
  MainContent,
  MainContentCard,
  MainContentHeader,
} from "../components/ContentTemplate";
import "./Companies.scss";
import { TextInput } from "../components/forms/TextInput";
import { useModal } from "../hooks/useModal";
import { Modal } from "../components/Modal";
import { useSnackBar } from "../hooks/useSnackBar";
import { SnackBar } from "../components/SnackBar";

export const Companies: React.FunctionComponent = () => {
  const [companies, setCompanies] = useState<Tenant[]>([]);
  const [modalVisible, openModal, closeModal] = useModal();
  const [tenantName, setTenantName] = useState<string>("");
  const [SnackBarVisible, openSnackBar, closeSnackBar] = useSnackBar();
  const [snackBarMsg, setSnackBarMsg] = useState<string>("");
  const [snackBarType, setSnackBarType] = useState<string>("");
  const [isValidated, setIsValidated] = useState<boolean>(true);

  useEffect(() => {
    async function fetchCompanies() {
      setCompanies(await getAllTenants());
    }
    fetchCompanies();
  }, []);

  const handleCreateNewTenant = async () => {
    if (tenantName === "") {
      setIsValidated(false);
      return;
    }

    const newTenant: TenantData = {
      name: tenantName,
    };
    try {
      createNewTenant(newTenant);
      setSnackBarMsg(`Kunden '${tenantName}' tillagd`);
      setSnackBarType("success");
    } catch (error) {
      setSnackBarMsg(`Kunde inte lägga till företaget '${tenantName}'`);
      setSnackBarType("error");
    } finally {
      setCompanies(await getAllTenants());
      openSnackBar();
      closeModal();
      setTenantName("");
      setIsValidated(true);
    }
  };

  const content = (
    <React.Fragment>
      <form>
        <TextInput
          name="companyName"
          placeholder="Företagsamn"
          required={true}
          value={tenantName}
          onChange={(e) => setTenantName(e.target.value)}
        />
        {isValidated ? "" : <div className="error-msg">Du måste fylla i företagsnamn</div>}
      </form>
    </React.Fragment>
  );
  const footer = (
    <React.Fragment>
      <div className="btn" onClick={closeModal}>
        Avbryt
      </div>
      <div className="btn primary" onClick={handleCreateNewTenant}>
        Lägg till
      </div>
    </React.Fragment>
  );

  return (
    <div className="KnegDashboard Companies">
      <MainNav />
      <MainContent>
        <MainContentHeader>
          <h3>Företag</h3>
        </MainContentHeader>
        <MainContentCard>
          <CardBody>
            <div className="companyList">
              {companies.map((company) => (
                <Link key={company.id} to={`/companies/${company.name}/${company.id}`} className="companyListItem">
                  {company.name}
                </Link>
              ))}
            </div>
          </CardBody>
          <CardFooter>
            <div className="btn primary" onClick={openModal}>
              Lägg till företag
            </div>
            <Modal
              visible={modalVisible}
              close={closeModal}
              modalHeader="Lägg till företag"
              modalContent={content}
              modalFooter={footer}
            />
          </CardFooter>
        </MainContentCard>
      </MainContent>
      <SnackBar
        visible={SnackBarVisible}
        close={closeSnackBar}
        text={snackBarMsg}
        type={snackBarType}
      />
    </div>
  );
};
