import React from "react";
import "./ContentTemplate.scss";

export const MainContent: React.FC<React.PropsWithChildren> = (props) => {
  return <div className="mainContent">{props.children}</div>;
};

export const MainContentHeader: React.FC<React.PropsWithChildren> = (props) => {
  return <div className="mainContentHeader">{props.children}</div>;
};

export const MainContentCard: React.FC<React.PropsWithChildren> = (props) => {
  return <div className="mainContentCard">{props.children}</div>;
};

export const CardHeader: React.FC<React.PropsWithChildren> = (props) => {
  return <div className="cardHeader">{props.children}</div>;
};

export const CardBody: React.FC<React.PropsWithChildren> = (props) => {
  return <div className="cardBody">{props.children}</div>;
};

export const CardFooter: React.FC<React.PropsWithChildren> = (props) => {
  return <div className="cardFooter">{props.children}</div>;
};
