import { KnegUser } from "../models/user";
import "./ProfileThumb.scss";

function getInitials(str: string) {
  const firstChars = str
    .split(" ")
    .map((word) => word[0])
    .join("");
  return firstChars;
}

// TODO: This doesn't look like a setter. Should perhaps be a good old component instead.
export function setProfilePicture(user: KnegUser | null) {
  if (user) {
    if (user.profilePictureUrl) {
      return (
        <div className="user-thumbnail">
          <div className="profile-picture">
            <img src={user.profilePictureUrl} alt={user.name} />
          </div>
        </div>
      );
    } else {
      const initials = getInitials(user.name);
      return (
        <div className="user-thumbnail">
          <div className="profile-initials">
            <span>{initials}</span>
          </div>
        </div>
      );
    }
  }
  return (
    <div className="user-thumbnail">
      <div className="profile-initials">
        <span className="noname"></span>
      </div>
    </div>
  );
}
