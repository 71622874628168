import React, { useState } from "react";
import "./DropDownMenu.scss";
import dropDownIcon from "../assets/img/more-v.svg";

export const DropDownMenu: React.FC<React.PropsWithChildren> = (props) => {
  const [showDropDown, setShowDropDown] = useState<boolean>(false);

  const toggleDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  const dismissHandler = (event: React.FocusEvent<HTMLButtonElement>): void => {
    if (event.currentTarget === event.target) {
      setShowDropDown(false);
    }
  };

  return (
    <div className="dropDownMenu">
      <button
        className={showDropDown ? "active" : undefined}
        onClick={(): void => toggleDropDown()}
        onBlur={(e: React.FocusEvent<HTMLButtonElement>): void => dismissHandler(e)}
      >
        <img src={dropDownIcon} alt="Dropdown toggle" />
        <ul className={showDropDown ? "options active" : "options"}>{props.children}</ul>
      </button>
    </div>
  );
};
