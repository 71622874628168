import "./SelectInput.scss";

export interface SelectOption {
  text: string;
  value: string;
}
export interface SelectInputProps {
  placeholder?: string;
  label?: string;
  name?: string;
  options: SelectOption[];
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
}
export const SelectInput: React.FC<SelectInputProps> = (props) => {
  const placeholder = props.placeholder || false;
  return (
    <label className="SelectInputLabel">
      {props.label}
      <select
        className="SelectInput"
        name={props.name}
        onChange={props.onChange}
        value={props.value}
      >
        {placeholder && (
          <option disabled defaultValue={""} value="">
            {props.placeholder}
          </option>
        )}
        {props.options.map((option) => (
          <option key={option.value} value={option.value}>{option.text}</option>
        ))}
      </select>
    </label>
  );
};
