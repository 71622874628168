import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  getDocs,
  orderBy,
  query,
  QueryDocumentSnapshot,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { firestoreDb } from "../config/firebase";
import { Task, TaskData, Template, TemplateData } from "../models/template";

const docsToTemplates = (doc: QueryDocumentSnapshot<DocumentData>): Template => ({
  id: doc.id,
  templatename: doc.get("templatename"),
});

export const getTemplates = async (tenantId: string): Promise<Template[]> => {
  const templatesFromTenantRef = query(
    collection(firestoreDb, "tenants/" + tenantId + "/templates")
  );
  const data = await getDocs(templatesFromTenantRef);
  const templateList = data.docs.map((doc) => docsToTemplates(doc));
  return templateList;
};

export const createNewTemplate = async (
  template: TemplateData,
  tenantId: string
): Promise<TemplateData> => {
  const newDoc = await addDoc(
    collection(firestoreDb, "tenants/" + tenantId + "/templates"),
    template
  );
  const createdTemplate: Template = {
    ...template,
    id: newDoc.id,
  };
  return createdTemplate;
};

export const updateTemplate = async (
  template: TemplateData,
  tenantId: string,
  templateId: string
) => {
  const templateRef = doc(firestoreDb, "tenants/" + tenantId + "/templates", templateId);
  await updateDoc(templateRef, {
    ...template,
  });
};

const deleteTasksForTemplate = async (tenantId: string, templateId: string) => {
  const taskData = await getDocs(
    query(collection(firestoreDb, "tenants/" + tenantId + "/templates/" + templateId + "/tasks"))
  );
  taskData.forEach(function (doc) {
    deleteDoc(doc.ref);
  });
  const infoData = await getDocs(
    query(collection(firestoreDb, "tenants/" + tenantId + "/templates/" + templateId + "/info"))
  );
  infoData.forEach(function (doc) {
    deleteDoc(doc.ref);
  });
};

export const deleteTemplate = async (tenantId: string, templateId: string) => {
  deleteTasksForTemplate(tenantId, templateId);
  await deleteDoc(doc(firestoreDb, "tenants/" + tenantId + "/templates", templateId))
    .then(() => {
      console.log("Doc deleted");
    })
    .catch((error) => {
      console.log("Something went wrong on delete ", error);
    });
};

const docsToTasks = (doc: QueryDocumentSnapshot<DocumentData>): Task => ({
  id: doc.id,
  title: doc.get("title"),
  steps: doc.get("steps"),
  infoFields: doc.get("infoFields"),
  createdAt: doc.get("createdAt"),
});

export const getTasks = async (
  tenantId: string,
  templateId: string,
  type: string
): Promise<Task[]> => {
  const tasksFromTenantRef = query(
    collection(firestoreDb, `tenants/${tenantId}/templates/${templateId}/${type}`),
    orderBy("createdAt")
  );
  const data = await getDocs(tasksFromTenantRef);
  const taskList = data.docs.map((doc) => docsToTasks(doc));
  return taskList;
};

export const createNewTask = async (
  task: TaskData,
  tenantId: string,
  templateId: string,
  type: string
): Promise<TaskData> => {
  const newDoc = await addDoc(
    collection(firestoreDb, `tenants/${tenantId}/templates/${templateId}/${type}`),
    task
  );
  const createdTask: Task = {
    ...task,
    id: newDoc.id,
    createdAt: Timestamp.now(),
  };
  return createdTask;
};

export const updateTask = async (
  task: TaskData,
  tenantId: string,
  templateId: string,
  taskId: string,
  type: string
) => {
  const taskRef = doc(firestoreDb, `tenants/${tenantId}/templates/${templateId}/${type}`, taskId);
  await updateDoc(taskRef, {
    ...task,
  });
};

export const deleteTask = async (
  tenantId: string,
  templateId: string,
  taskId: string,
  type: string
) => {
  await deleteDoc(doc(firestoreDb, `tenants/${tenantId}/templates/${templateId}/${type}`, taskId))
    .then(() => {
      console.log("Doc deleted");
    })
    .catch((error) => {
      console.log("Something went wrong on delete ", error);
    });
};
