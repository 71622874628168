import React from "react";
import { MainNav } from "../components/MainNav";
import "./Payment.scss";
import knegMini from "../assets/img/knegMini.svg";
import knegMid from "../assets/img/knegMellan.svg";
import wallet from "../assets/img/wallet.svg";
import {
  CardBody,
  MainContent,
  MainContentCard,
  MainContentHeader,
} from "../components/ContentTemplate";

export const Payment: React.FunctionComponent = () => {
  return (
    <div className="KnegDashboard Payment">
      <MainNav />
      <MainContent>
        <MainContentHeader>
          <h3>Prenumeration</h3>
        </MainContentHeader>
        <MainContentCard>
          <CardBody>
            <div className="payment-grid grid gap-20">
              <div className="col col-6">
                <h6>Din prenumeration</h6>
                <div className="subtype">
                  <img src={knegMini} alt="Kneg Mini" />
                  <div>
                    <h5>Kneg Mini</h5>
                    <p>
                      <b>179 kr / mån per anställd</b>
                    </p>
                  </div>
                </div>
                <p>
                  Antal anställda
                  <br />
                  <b>5 knegare</b>
                </p>
                {/* eslint-disable-next-line */}
                <a href="#" className="btn outline">
                  Avsluta prenumeration
                </a>
              </div>
              <div className="col col-6">
                <h6>Nästa prisnivå</h6>
                <div className="subtype">
                  <img src={knegMid} alt="Kneg Mellan" />
                  <div>
                    <h5>Kneg Mellan</h5>
                    <p>
                      <b>159 kr / mån per anställd</b>
                    </p>
                  </div>
                </div>
                <p>
                  För att nå upp till nästa nivå behöver du
                  <br />
                  <b>+ 1 knegare</b>
                </p>
                {/* eslint-disable-next-line */}
                <a href="#" className="btn outline">
                  Lägg till användare
                </a>
              </div>
              <div className="col col-12">
                <h6>Nästa betalning</h6>
                <div className="subtype">
                  <img src={wallet} alt="Betalning" />
                  <div>
                    <h5>895 kronor</h5>
                    <p>
                      <b>Dras den 25 Oktober</b>
                    </p>
                  </div>
                </div>
                {/* eslint-disable-next-line */}
                <a href="#" className="btn outline">
                  Hantera prenumeration
                </a>
              </div>
            </div>
          </CardBody>
        </MainContentCard>
      </MainContent>
    </div>
  );
};
