import {
  collection,
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { InviteUser, KnegUser } from "../models/user";
import { firestoreDb } from "../config/firebase";

const docToKnegUser = (doc: DocumentSnapshot<DocumentData>): KnegUser => ({
  id: doc.id,
  email: doc.get("email"),
  name: doc.get("name"),
  profilePictureUrl: doc.get("profilePictureUrl"),
  role: doc.get("role"),
  lastActive: doc.get("lastActive"),
  platform: doc.get("platform"),
  tenant: doc.get("tenant"),
  superuser: doc.get("isSuperAdmin"),
});

const docToInviteUser = (doc: DocumentSnapshot<DocumentData>): InviteUser => ({
  id: doc.id,
  email: doc.get("email"),
  role: doc.get("role"),
  consumed: doc.get("consumed"),
  consumedDate: doc.get("consumedDate"),
});

export const getKnegUser = async (firebaseUid: string): Promise<KnegUser> => {
  const userRef = doc(firestoreDb, "users", firebaseUid);
  const data = await getDoc(userRef);
  return docToKnegUser(data);
};

export const getUsersByTenantId = async (tenantId: string): Promise<KnegUser[]> => {
  const usersFromTenantRef = query(
    collection(firestoreDb, "users"),
    where("tenant", "==", tenantId)
  );
  const data = await getDocs(usersFromTenantRef);
  const userList = data.docs.map((doc) => docToKnegUser(doc));
  return userList;
};

export const getInvitationsByTenantId = async (tenantId: string): Promise<InviteUser[]> => {
  const invitationsFromTenantRef = query(
    collection(firestoreDb, "tenants/" + tenantId + "/invitations"),
    where("consumed", "==", false)
  );
  const data = await getDocs(invitationsFromTenantRef);
  const userInvitesList = data.docs.map((doc) => docToInviteUser(doc));
  return userInvitesList;
};
