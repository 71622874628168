import { useContext, useEffect, useState } from "react";
import { CardBody, CardFooter } from "./ContentTemplate";
import { useModal } from "../hooks/useModal";
import { setProfilePicture } from "./ProfileThumb";
import { Modal } from "./Modal";
import { UserContext } from "../config/components/UserContext";
import { httpsCallable } from "firebase/functions";
import { functions } from "../config/firebase";
import { FirebaseError } from "firebase/app";
import { DateTime } from "luxon";

import addUser from "../assets/img/add-user.svg";
import deleteIcon from "../assets/img/delete.svg";
import { TextInput } from "./forms/TextInput";
import { SelectInput } from "./forms/SelectInput";
import { InviteUser, KnegUser } from "../models/user";

import "./UserManagementList.scss";
import { getInvitationsByTenantId, getUsersByTenantId } from "../dataaccess/users";
import { roleTextConverter } from "../utils/roleRename";

/*

TODO:
- Move data access code to data access layer
- Extract snackbar code to some kind of global thing
- Load and show real invitations
- Only allow adding new users to aritrary tenant if superuser
*/

type Props = {
  tenantId: string;
  setSnackBarMessage: (msg: string) => void;
  openSnackBar: () => void;
  setSnackBarType: (t: string) => void;
};

export const UserManagementList = (props: Props) => {
  const [addUserModalVisible, openAddUserModal, closeAddUserModal] = useModal();
  const [newUserEmail, setNewUserEmail] = useState<string>("");
  const [newUserRole, setNewUserRole] = useState<string>("employee");
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const knegUser = useContext(UserContext);

  const { setSnackBarMessage, openSnackBar, setSnackBarType } = props;

  const [users, setUsers] = useState<KnegUser[]>([]);
  const [invites, setInvites] = useState<InviteUser[]>([]);
  useEffect(() => {
    async function fetchTenantUsers(companyId: string) {
      setUsers(await getUsersByTenantId(companyId));
      setInvites(await getInvitationsByTenantId(companyId));
    }
    fetchTenantUsers(props.tenantId);
  }, [props.tenantId]);

  const handleInviteNewUser = async () => {
    console.log("Handle invite. Email is", newUserEmail, "role is", newUserRole);
    const inviteNewUser = httpsCallable(functions, "inviteNewUser");
    if (knegUser === null) return;
    console.log("About to try to invite user");
    setSubmitting(true);
    try {
      await inviteNewUser({
        tenantId: props.tenantId,
        toEmail: newUserEmail,
        role: newUserRole,
      });
      console.log("Seems to work.");
      /* Should add some sort of snackbar on success and errors */
      setSubmitting(false);
      closeAddUserModal();
      setSnackBarMessage("Ny knegare tillagd!");
      setSnackBarType("success");
      openSnackBar();
    } catch (err) {
      if (err instanceof FirebaseError) {
        console.log("Firebase error", err.code);
        setSnackBarType("error");
        setSubmitting(false);
        if (err.code === "functions/unauthenticated") {
          console.log("Not allowed to invite user");
          setSnackBarMessage("Not allowed to invite user");
        } else if (err.code === "functions/failed-precondition") {
          console.log("Some data is missing or wrong.", err.message);
          setSnackBarMessage("Some data is missing or wrong. - " + err.code + " " + err.message);
        } else {
          console.log("Unknown error!", err.code, err.message);
          setSnackBarMessage("Unknown error!");
        }
      } else {
        setSubmitting(false);
        console.log("Something went wrong.");
        console.log("Error", err);
      }
      openSnackBar();
      // TODO: Handle error well in UI.
    }
  };

  const content = (
    <>
      <form>
        <TextInput
          placeholder="E-post"
          type="email"
          required={true}
          value={newUserEmail}
          onChange={(e) => setNewUserEmail(e.target.value)}
        />
        <SelectInput
          options={[
            { text: "Arbetsledare", value: "manager" },
            { text: "Servicetekniker", value: "employee" },
          ]}
          placeholder="Välj roll"
          onChange={(e) => setNewUserRole(e.target.value)}
          value={newUserRole}
        />
      </form>
      <p className="info-text">
        När du lägger till en knegare kommer ett mail med en inbjudan automatiskt att skickas ut
        till den angivna e-posten.
      </p>
    </>
  );

  const footer = (
    <>
      <div className="btn" onClick={closeAddUserModal}>
        Avbryt
      </div>
      <div className={"btn primary " + (isSubmitting && "loading")} onClick={handleInviteNewUser}>
        Lägg till
      </div>
    </>
  );

  return (
    <>
      <CardBody>
        <table className="UserManagementList">
          <thead>
            <tr>
              <th></th>
              <th>Namn</th>
              <th>E-post</th>
              <th>Roll</th>
              <th>Aktiv</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>{setProfilePicture(user)}</td>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.superuser ? "Kneg Admin" : roleTextConverter(user.role)}</td>
                <td>
                  {(user.lastActive ? DateTime.fromISO(user.lastActive).toRelative() : "Okänt") +
                    (user.platform ? ", " + user.platform : "")}
                </td>
                <td className="iconCol">
                  <img src={deleteIcon} alt="Ta bort" />
                </td>
              </tr>
            ))}
            {invites.map((invite) => (
              <tr key={invite.id} className="pendingInvitation">
                <td>{setProfilePicture(null)}</td>
                {invite.consumed ? (
                  <td>
                    <span className="status accepted"></span>Inbjudan godkänd
                  </td>
                ) : (
                  <td>
                    <span className="status pending"></span>Inbjudan skickad
                  </td>
                )}
                <td>{invite.email}</td>
                <td>{roleTextConverter(invite.role)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </CardBody>
      <CardFooter>
        <button className="btn icon-btn primary" onClick={openAddUserModal}>
          <img src={addUser} alt="" />
          Lägg till knegare
        </button>
        <Modal
          visible={addUserModalVisible}
          close={closeAddUserModal}
          modalHeader="Lägg till knegare"
          modalContent={content}
          modalFooter={footer}
        />
      </CardFooter>
    </>
  );
};
