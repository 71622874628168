import { MainContent, MainContentCard, MainContentHeader } from "../components/ContentTemplate";
import React, { useContext, useState } from "react";
import { MainNav } from "../components/MainNav";
import { useSnackBar } from "../hooks/useSnackBar";
import { SnackBar } from "../components/SnackBar";
import "./UserListPage.scss";
import "../styles/mui-overrides.scss";
import { UserManagementList } from "../components/UserManagementList";
import { UserContext } from "../config/components/UserContext";

export const UserListPage: React.FunctionComponent = (props) => {
  const [SnackBarVisible, openSnackBar, closeSnackBar] = useSnackBar();
  const [snackBarMsg, setSnackBarMsg] = useState<string>("");
  const [snackBarType, setSnackBarType] = useState<string>("");
  const knegUser = useContext(UserContext);
  const tenant = knegUser?.tenant;

  if (!tenant) return <div></div>; // Can this happen while loading? Or is it an error?

  return (
    <div className="KnegDashboard UserListPage">
      <MainNav />
      <MainContent>
        <MainContentHeader>
          <h3>Knegare</h3>
        </MainContentHeader>
        <MainContentCard>
          <UserManagementList
            tenantId={tenant}
            setSnackBarMessage={setSnackBarMsg}
            openSnackBar={openSnackBar}
            setSnackBarType={setSnackBarType}
          />
        </MainContentCard>
      </MainContent>
      <SnackBar
        visible={SnackBarVisible}
        close={closeSnackBar}
        text={snackBarMsg}
        type={snackBarType}
      />
    </div>
  );
};
