import React, { useState } from "react";

export function useForm<T>(callback: () => void, initialState: T) {
  const [values, setValues] = useState<T>(initialState);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    callback(); // triggering the callback
  };

  return {
    onChange,
    onSubmit,
    values,
  };
}
