import {
  collection,
  DocumentData,
  getDocs,
  query,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { Assignment } from "../models/assignment";
import { firestoreDb } from "../config/firebase";

const docsToProjects = (doc: QueryDocumentSnapshot<DocumentData>): Assignment => ({
  id: doc.id,
  address: doc.get("address"),
  contactPerson: doc.get("contactPerson"),
  createdAt: doc.get("createdAt"),
  createdBy: doc.get("createdBy"),
  customer: doc.get("customer"),
  email: doc.get("email"),
  finishedBy: doc.get("finishedBy"),
  name: doc.get("name"),
  offerCreatedBy: doc.get("offerCreatedBy"),
  offerId: doc.get("offerId"),
  phoneNumber: doc.get("phoneNumber"),
  startedBy: doc.get("startedBy"),
  status: doc.get("status"),
});

export const getProjects = async (tenantId: string): Promise<Assignment[]> => {
  const projectsFromTenantRef = query(collection(firestoreDb, "tenants/" + tenantId + "/projects"));
  const data = await getDocs(projectsFromTenantRef);
  const projectList = data.docs.map((doc) => docsToProjects(doc));
  return projectList;
};
