import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { UserListPage } from "./pages/UserListPage";
import Login from "./pages/Login";
import { AuthRoute } from "./config/components/AuthRoute";
import { Assignments } from "./pages/Assignments";
import { AppSettings } from "./pages/AppSettings";
import { Payment } from "./pages/Payment";
import { NotFound } from "./pages/NotFound";
import { Companies } from "./pages/Companies";
import { Customers } from "./pages/Customers";
import { CompanyDetailPage } from "./pages/CompanyDetailPage";
import { RedeemInvitationPage } from "./pages/registration/RedeemInvitationPage";
import { UserDetailpage } from "./pages/UserDetailPage";
import { TemplateList } from "./pages/TemplatesListPage";
import { Template } from "./pages/TemplatePage";
import { UserContext } from "./config/components/UserContext";

import "./App.scss";
/* initializeApp(config.firebaseConfig); */

function App() {
  const knegUser = React.useContext(UserContext);
  return (
    <BrowserRouter>
      <Routes>
        {knegUser?.superuser && <Route
          path="/companies"
          element={
            <AuthRoute>
              <Companies />
            </AuthRoute>
          }
        />}
        <Route
          path="/companies/:companyName/:companyId"
          element={
            <AuthRoute>
              <CompanyDetailPage />
            </AuthRoute>
          }
        />{" "}
        <Route
          path="/user/:userId"
          element={
            <AuthRoute>
              <UserDetailpage />
            </AuthRoute>
          }
        />{" "}
        <Route
          path="/users"
          element={
            <AuthRoute>
              <UserListPage />
            </AuthRoute>
          }
        />
        <Route
          path="/assignments"
          element={
            <AuthRoute>
              <Assignments />
            </AuthRoute>
          }
        />
        <Route
          path="/customers"
          element={
            <AuthRoute>
              <Customers />
            </AuthRoute>
          }
        />
        <Route
          path="/templates"
          element={
            <AuthRoute>
              <TemplateList />
            </AuthRoute>
          }
        />
        <Route
          path="/templates/:templateName/:templateId"
          element={
            <AuthRoute>
              <Template />
            </AuthRoute>
          }
        />{" "}
        <Route
          path="/settings"
          element={
            <AuthRoute>
              <AppSettings />
            </AuthRoute>
          }
        />
        <Route
          path="/payments"
          element={
            <AuthRoute>
              <Payment />
            </AuthRoute>
          }
        />
        <Route path="/register/i/:tenantId/:invitationId" element={<RedeemInvitationPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Navigate to="/users" replace />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
